import React, { useContext } from 'react';
import { Grid, ResponsiveContext } from 'grommet';
import { SitesDataProd, DashboardCard } from '.';

export const DashboardGridProd = ({ ...rest }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Grid pad={{ bottom: 'medium' }}
      columns={!['xsmall', 'small'].includes(size) ? 'medium' : '100%'}
      rows={[['auto', 'full']]}
      gap="medium"
      fill
      {...rest}
    >
      {SitesDataProd &&
        SitesDataProd.map((datum, index) => <DashboardCard key={index} card={datum} />)}
    </Grid>
  );
};