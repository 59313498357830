import React, { useContext } from 'react';
import { Grid, ResponsiveContext } from 'grommet';
import { SitesDataITG, DashboardCard } from '.';

export const DashboardGridITG = ({ ...rest }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Grid pad={{ bottom: 'medium' }}
      columns={!['xsmall', 'small'].includes(size) ? 'medium' : '100%'}
      rows={[['auto', 'full']]}
      gap="medium"
      fill
      {...rest}
    >
      {SitesDataITG &&
        SitesDataITG.map((datum, index) => <DashboardCard key={index} card={datum} />)}
    </Grid>
  );
};