import React from "react";
import { Grommet, Box, ResponsiveContext } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { Header, Home, Notification } from './components';
import { BrowserRouter, Route, Routes } from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
      <Grommet className="App" theme={hpe} full>
        <ResponsiveContext.Consumer>
          {size => (

            <Box background="background-back"> {/* new box */}

              <Box elevation="medium">
                 <Header />
              </Box>
          
              <Box flex="grow">
                <Notification/>
              </Box>

              <Box margin={{ top: 'xsmall' }} fill>
                <Routes>
                  <Route path="/" element={<Home />} />  
                  <Route path="*" element={<Home />} />
                </Routes>
              </Box>

            </Box>


          )}
        </ResponsiveContext.Consumer>
      </Grommet>  
    </BrowserRouter>
  );
}

export default App;
