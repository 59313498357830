//import React, { useContext } from 'react';
import { PageHeader, Page, PageContent, Heading } from 'grommet';
import { DashboardGridProd, DashboardGridITG, DashboardFooter } from '.';

export const Home = () => {
  //const [user, setUser] = useState(defaultUser);
  //const size = useContext(ResponsiveContext);

  return (
    <Page background="background-back">
      <PageContent> 

          <PageHeader title="Hello" size="medium" pad={{ top: 'small' }} margin={{ top: 'medium' }}
            subtitle={`Welcome to the HPE Status Central community!`}/>

          <Heading size="small" margin={{ top: 'large' }}>Production Pages:</Heading>

          <DashboardGridProd /> 

          <Heading size="small" margin={{ top: 'large' }}>ITG Test Pages:</Heading>

          <DashboardGridITG /> 

          <DashboardFooter/>

      </PageContent>  

    </Page>
  );
};