import React, { useState } from "react";
import { Notification, Collapsible } from 'grommet';

const BannerNotification = () => {

  const [showNotification, setShowNotification] = useState(true);

  return (
    <Collapsible open={showNotification}>
      <Notification
        status="normal"
        title="News flash!"
//        status="warning"
//        title="Warning"      
        margin={{ top: '5px' }}
        onClose={() => setShowNotification(false)}
        actions={[
          {
            href: 'https://hpe.sharepoint.com/teams/Global_Workplace',
            label: 'More info',
          },
        ]}
        message="Welcome Global Workplace to the Status Central community."
        global
      />
    </Collapsible>
   );
};

export { BannerNotification as Notification };