//import { Button } from 'grommet';
//import styled from 'styled-components';
import {
//  DocumentUpdate,
  Cloud,
  Group,
//  Aruba,
//  PhoneVertical,
//  ShieldSecurity,
//  Support,
//  UserAdd,
} from 'grommet-icons';

// styled component to create custom orange button
// const StyledButton = styled(Button)`
//   background: ${props => props.theme.global.colors['orange!']};
//   color: ${props => props.theme.global.colors['text-strong'].light};
//   &:hover {
//     background: ${props => props.theme.global.colors['orange!']};
//     color: ${props => props.theme.global.colors['text-strong'].light};
//   }
// `;

export const SitesDataITG = [
  // {
  //   cta: <StyledButton label="Launch" />,
  //   background: 'yellow',
  //   title: 'Aruba Network Manager',
  //   description: `Network configuration and operations made simple. Your 
  //   network on a single pane of glass.`,
  //   descriptionColor: 'text-strong',
  //   icon: <Aruba color="plain" />,
  // },
  {
    cta: 'Launch',
    title: 'External Status Central (test)',
    description:
      'HPE\'s partner and employee facing Status Central site for critical systems and services (commerce, etc.).',
    icon: <Cloud color="blue!" />,
    url: 'https://it-itg.hpe-status.com/',
  },
  {
    cta: 'Launch',
    title: 'Global Workplace (test)',
    description:
      'Global Workplace\'s internal Status Central site for HPE facilities world wide.',
    icon: <Group color="purple!" />,
    url: 'https://workplace-itg.hpe-status.com/',
  },
  // {
  //   cta: 'Launch',
  //   title: 'IT Status Central (test)',
  //   description: 'HPE IT\'s internal Status Central site for status news and updates for HPE employees.',
  //   icon: <Support color="yellow!" />,
  // },
  // {
  //   cta: 'Set up SAML SSO',
  //   title: 'Add a SSO/SAML connection',
  //   description: `Easily add extra protection to your HPE Account by connecting 
  //   to your company's IDP.`,
  //   icon: <ShieldSecurity color="blue!" />,
  // },
  // {
  //   cta: 'Enable MFA',
  //   title: 'Set up multi-factor authentication  ',
  //   description:
  //     'Easily add extra protection by requiring access to your phone.',
  //   icon: <PhoneVertical color="purple!" />,
  // },
  // {
  //   cta: 'Release notes',
  //   title: 'Get release notes',
  //   description:
  //     'Stay up to date with the latest release notes from HPE Common Cloud.',
  //   icon: <DocumentUpdate color="blue!" />,
  // },
];